import * as React from "react";
import { useLocation, Navigate } from "react-router-dom";

const AuthContext = React.createContext(null);

function AuthProvider({ children }) {
  const [user, setUser] = React.useState(null);
  React.useEffect(() => {
    const anvuiUser = localStorage.getItem('anvuiUser') || null;
    setUser(anvuiUser)
  }, [])
  const signIn = (newUser, token, callback) => {
    localStorage.setItem('accessToken', token);
    localStorage.setItem('anvuiUser', newUser);
    setUser(newUser);
    callback();
  };

  const signOut = (callback) => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('anvuiUser');
    setUser(null);
    callback();
  };

  const value = { user, signIn, signOut };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

const PrivateRoute = ({children}) => {
  const tokenAuth = localStorage.getItem("accessToken");
  const location = useLocation();
  if (!tokenAuth) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

export {AuthProvider, useAuth, PrivateRoute}