import React, {useState, useEffect, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {api} from "../../components/Api/api";
import Loading from "../../components/Loading";
import {Form, Input, Button, message, Space} from "antd";
import CKEditorForm from "../../utility/CKEditorForm";
import {replaceSpecialCharacters, cleanUnusedImages} from "../../tools";
import {useAuth} from "../../components/Auth";

const {Item} = Form;

const CreatePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const folderName = useRef(Math.floor(Date.now() / 1000));
  const content = useRef(null);
  const [form] = Form.useForm();
  const url = location.pathname + `?id=${id}`;
  const auth = useAuth();

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await api.get(url);
      const resData = res.data;
      const create_time = resData.create_time;

      folderName.current = create_time;
      content.current = resData.content;

      setData(resData);
    } catch (err) {
      if (err === 401) {
        auth.signOut(() => navigate("/login"));
      } else {
        navigate("/error");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }

    return () => {
      cleanUnusedImages(
        api,
        `${location.pathname}/clean-images`,
        content.current,
        folderName.current
      );
    };
  }, []);

  const handleInput = (e) => {
    const value = e.currentTarget.value;

    setData({...data, title: value, alias: replaceSpecialCharacters(value)});
  };

  const handleCKEditorChange = (value) => {
    setData({...data, content: value});
  };

  const cancel = () => {
    navigate("/page");
  };

  const onFinish = async () => {
    if (!data || !data.title) {
      message.error("Vui lòng nhập tiêu đề bài viết.");
    } else if (data.title.length < 7) {
      message.error("Tiêu đề dài hơn 6 kí tự.");
    } else {
      let values = data;
      values.status = 1;
      values.create_time = data.create_time
        ? data.create_time
        : folderName.current;
      content.current = values.content;

      setLoading(true);
      await api
        .post(id ? url : location.pathname, values)
        .then((res) => {
          setLoading(false);
          message.success(res.data.msg);
          navigate("/page");
        })
        .catch((err) => {
          navigate("/error");
        });
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <Form
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          form={form}
          initialValues={data}
          layout="vertical"
        >
          <Item label="Tiêu đề">
            <Input value={data?.title} onChange={(e) => handleInput(e)} />
          </Item>
          <Item layout="horizontal" label="Đường dẫn">
            {data?.alias}
          </Item>
          <Item label="Nội dung trang">
            <CKEditorForm
              data={data.content}
              handleChange={(value) => handleCKEditorChange(value)}
              url="/page/create-page/upload-image"
              name={folderName.current}
            />
          </Item>

          <Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Lưu
              </Button>
              <Button type="primary" onClick={() => cancel()}>
                Huỷ
              </Button>
            </Space>
          </Item>
        </Form>
      )}
    </div>
  );
};

export default CreatePage;
