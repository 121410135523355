import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const token = "accessToken";

api.interceptors.request.use(
  (response) => {
    const accessToken = localStorage.getItem(token);
    if (accessToken) {
      response.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.status);
  }
);

export {api};
